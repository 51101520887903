@font-face {
  font-family: "RobotoLight";
  src: local("RobotoLight"),
    url("../../font/Roboto/Roboto-Light.ttf") format("truetype");
}
@font-face {
  font-family: "RobotoMedium";
  src: local("RobotoMedium"),
    url("../../font/Roboto/Roboto-Medium.ttf") format("truetype");
}
.font-medium {
  font-family: "RobotoMedium";
}

@import "./base/bootstrap.scss";
@import "./base/bootstrap-extended.scss";
@import "./base/colors.scss";
@import "./base/components.scss";

// Themes
@import "./base/themes/dark-layout.scss";
@import "./base/themes/bordered-layout.scss";
@import "./base/themes/semi-dark-layout.scss";
// @import './base/custom-rtl.scss';

// React Specific
@import "react/index";
